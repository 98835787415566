import React, { useState, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react.js';
import SwiperCore, { Navigation } from 'swiper';
import ReactHtmlParser from 'react-html-parser';
import WithDeviceType from '../../HOCs/WithDeviceType';
import { reinitSwiper } from '../../functions/functions';
import { promoDataAPI } from '../../../api';
import './index.scss';
import { domain } from '../../../constants';

const Reviews = ({ desktop, style, children }) => {
  const dataAPI = new promoDataAPI('razrabotkaim.json');
  const [data, setData] = useState([]);
  useEffect(() => {
    dataAPI.sendInfo('reviews').then((res) => setData(res));
  }, []);

  SwiperCore.use([Navigation]);
  const initNav = (swiper) => {
    swiper.navigation.prevEl.innerHTML = `<svg width="37" height="39" viewBox="0 0 37 39" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M19.7618 39L-6.45189e-06 19.5L19.7618 2.30769e-06L23.7142 3.9L10.6995 16.7423L37 17.0098L37 22.5252L10.6995 22.2577L23.7142 35.1L19.7618 39Z" fill="url(#paint0_radial)"/>
                                                <defs>
                                                    <radialGradient id="paint0_radial" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(33.6814 39) rotate(-132.095) scale(50.2437 72.0712)">
                                                        <stop offset="0.0272715" stop-color="#FA3D33"/>
                                                        <stop offset="1" stop-color="#EE8B52"/>
                                                    </radialGradient>
                                                </defs>
                                            </svg>`;

    swiper.navigation.nextEl.innerHTML = `<svg width="37" height="39" viewBox="0 0 37 39" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M17.2382 3.01402e-06L37 19.5L17.2382 39L13.2858 35.1L26.3005 22.2577L-7.55217e-06 21.9902L-5.65799e-06 16.4748L26.3005 16.7423L13.2858 3.9L17.2382 3.01402e-06Z" fill="url(#paint0_radial)"/>
                                                <defs>
                                                <radialGradient id="paint0_radial" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(3.31856) rotate(47.9051) scale(50.2437 72.0712)">
                                                <stop offset="0.0272715" stop-color="#FA3D33"/>
                                                <stop offset="1" stop-color="#EE8B52"/>
                                                </radialGradient>
                                                </defs>
                                                </svg>
                                                `;
  };

  return (
    <section className="reviews" style={style}>
      <div className="wrapper">
        {children}
        <div className="reviews__slider">
          <Swiper
            data-aos="fade-in"
            data-aos-delay="300"
            spaceBetween={60}
            navigation
            slidesPerView={desktop ? 'auto' : 1}
            onSwiper={(swiper) => {
              reinitSwiper(swiper);
              initNav(swiper);
            }}
          >
            {data.length > 0 &&
              data.map((item, index) => (
                <SwiperSlide
                  key={`team--${index}`}
                  className="review__slide"
                // data-aos="fade-up"
                // data-aos-delay={(index + 1) * 100}
                >
                  <div className="review">
                    <div className="review__person">
                      <div className="review__image">
                        <img alt="Человек, оставивший отзыв" src={item.pic} />
                      </div>
                      <div className="review__bio">
                        <span className="review__name">{ReactHtmlParser(item.person)}</span>
                        <span className="review__position">{ReactHtmlParser(item.position)}</span>
                      </div>
                    </div>
                    <div className="review__info">
                      <span>{ReactHtmlParser(item.review)}</span>
                      <a href={item.ref} className="review__original" target="_blank">
                        ОРИГИНАЛ
                      </a>
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            <SwiperSlide
              className="review__slide"
            >
              <div className="review">
                <div className="review__person">
                  <div className="review__image">
                    <img alt="Человек, оставивший отзыв" src={require("../../../assets/images/reviews/red-collar.png")} />
                  </div>
                  <div className="review__bio">
                    <span className="review__name">Ломов Денис Александрович</span>
                    <span className="review__position">Генеральный директор ООО Ред Коллар</span>
                  </div>
                </div>
                <div className="review__info">
                  <span>
                    Мы выражаем искреннюю благодарность компании ASAP за их работу в роли партнера Red Collar. Наше сотрудничество превратилось в приятное путешествие, вдохновленное общими целями. Мы рады достигнутым результатам и уверены, что будем двигаться дальше.

                    Хочется отметить профессионализм команды ASAP, способность решать сложные задачи и творчески подходить к проблемам. Они всегда думают наперед, чтобы обеспечить успех проекта...</span>
                  <a href={'https://drive.google.com/file/d/12VVElNqICnRh8ZsoTFB4GiBqfb-JSR0r/view'} className="review__original" target="_blank">
                    ОРИГИНАЛ
                  </a>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide
              className="review__slide"
            // data-aos="fade-up"
            >
              <div className="review">
                <div className="review__person">
                  <div className="review__image">
                    <img alt="Человек, оставивший отзыв" src={`${domain}//storage/review__person_1.png`} />
                  </div>
                  <div className="review__bio">
                    <span className="review__name">Руслан Александрович</span>
                    <span className="review__position">Менеджер проекта Minervasoft</span>
                  </div>
                </div>
                <div className="review__info">
                  <span>
                    Ребята удивили, смогли кастомизировать платформу Tilda. Предлагали достаточно логичные изменения по UX. Были на связи во внерабочее время. Соблюдали сроки, внесли срочные правки даже 30 декабря, и сдали проект вовремя. Отдельное спасибо!
                  </span>
                  <a href={'https://minervasoft.ru/'} className="review__original" target="_blank">
                    ПРОЕКТ
                  </a>
                </div>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      </div>
    </section>
  );
};

export default WithDeviceType(Reviews);
